<template>
  <div class="div-form-main cd-note-form" v-loading="formLoading">
    <div class="div-form-main-btn" >
      <el-button round type="primary"  @click="submitCdNote(true)" v-if="(propsView === 'CREATE_CD_NOTE' && hasPermission($permissionFunctionCode.ADD))">Submit</el-button>
      <el-button round type="primary"  v-if="propsView === 'EDIT_CD_NOTE' && !isCdFormAllFieldsDisabled && hasPermission($permissionFunctionCode.EDIT)" @click='submitCdNote(false)'>Submit</el-button>
      <el-button round type="primary"  v-if="propsView === 'EDIT_CD_NOTE' && isCdFormAllFieldsDisabled && hasPermission($permissionFunctionCode.EDIT) && (cdNoteBasInfo.status === CDNOTE_STATUS.AUTHORIZED_SIGNATORY_REJECTED || cdNoteForm.status === CDNOTE_STATUS.CREDIT_AUTHORIZATION_REJECTED)" @click='isCdFormAllFieldsDisabled = false'>Edit</el-button>
      <el-button v-if="!isViewOnly" round outline   @click="handleCloseCdNoteForm('CLOSE')">Close</el-button>
    </div>
    <div class="div-form-main-container" v-if="['CREATE_CD_NOTE','EDIT_CD_NOTE','MAIN_VIEW'].includes(propsView)">
      <el-form :model="cdNoteForm" :rules="rules" ref="cdNoteForm">
        <div  class="basic-card-block  margin-top-0" >
          <h1>{{cdNoteForm.noteType === 'CREDIT_NOTE' ? 'Credit' : 'Debit'}} Note Information</h1>
          <div>
            <el-button class="el-button-select-invoice" v-if="propsView === 'CREATE_CD_NOTE'"
              round outline size="small" @click="dialogSelectInvoice = true"
            >
              <span class="span-red-asterik">*</span>Select Invoice
            </el-button>
            <div class="div-frac-15">
              <el-form-item label="Invoice Number" prop="invoiceNo">
                <el-input disabled v-model="cdNoteForm.invoiceNo"/>
              </el-form-item>
              <el-form-item label="Company Name" prop="customerCompanyName">
                <el-input v-model="cdNoteForm.customerCompanyName" disabled/>
              </el-form-item>
              <el-form-item label="Vessel Name/ Terminal" prop="orderInfo">
                <el-input v-model="cdNoteForm.orderInfo" disabled/>
              </el-form-item>
              <el-form-item label="Note Date" prop="noteDate">
                <el-date-picker v-model="cdNoteForm.noteDate"  :disabled="isCdFormAllFieldsDisabled"/>
              </el-form-item>
              <el-form-item label="Due Date" prop="noteDueDate">
                <el-date-picker v-model="cdNoteForm.noteDueDate" @change="(e) => cdNoteForm.noteDueDate = e.toISOString()" :disabled="isCdFormAllFieldsDisabled"/>
              </el-form-item>
              <el-form-item class="term" label="Terms" prop="term" v-if="cdNoteForm.noteType === 'DEBIT_NOTE'">
                <el-select v-model="cdNoteForm.term" :disabled="isCdFormAllFieldsDisabled" @change="handleChangeTerm">
                  <el-option
                    v-for="item in PAYMENT_TERM_OPTIONS"
                    :key="item.value" :label="item.label" :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item class="termDate" v-if="cdNoteForm.noteType === 'DEBIT_NOTE' && cdNoteForm.term ==='SPECIFIC_DATE'" label="Term Date" prop="termDate" >
                <el-date-picker
                  :disabled="isCdFormAllFieldsDisabled"
                  v-model="cdNoteForm.termDate"
                  type="date"
                  >
                </el-date-picker>
              </el-form-item>
              <el-form-item  v-if="cdNoteForm.noteType === 'DEBIT_NOTE' && cdNoteForm.term !=='SPECIFIC_DATE'" label="Credit Days" prop="creditDay">
                <el-input-number
                  v-model="cdNoteForm.creditDay"
                  :disabled="isCdFormAllFieldsDisabled || cdNoteForm.term ==='IMMEDIATE'"
                  :controls="false" :precision="0" :min="0"
                />
              </el-form-item>
            </div>
            <div class="div-frac-1">
              <el-form-item label="Reason" prop="reason">
                <el-input v-model="cdNoteForm.reason" :disabled="isCdFormAllFieldsDisabled"/>
              </el-form-item>
            </div>
          </div>
        </div>
        <div  class="basic-card-block">
          <h1>Item</h1>
          <div>
            <div class="div-frac-14">
              <el-form-item label="Item Type" prop="itemType">
                <el-select
                  v-model="cdNoteForm.itemType" @change="handleCdNoteItemTypeSelect"
                  :disabled="(propsView === 'CREATE_CD_NOTE' && !cdNoteForm.invoiceNo) || isCdFormAllFieldsDisabled"
                >
                  <el-option
                    v-for="item in itemTypeList"
                    :key="item.value" :label="item.label" :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="Description" prop="description">
                <el-select
                  v-model="cdNoteForm.description" @change="handleCdNoteItemSelect"
                  value-key="id" :allow-create="cdNoteForm.itemType === 'OTHER_COST'" filterable
                  :disabled="(propsView === 'CREATE_CD_NOTE' && !cdNoteForm.itemType) || isCdFormAllFieldsDisabled"
                >
                  <el-option
                    v-for="item in dropDownDescList"
                    :key="item.id" :label="item.description" :value="item"
                  />
                </el-select>
              </el-form-item>
              <div class="price-uom-input"  v-if="cdNoteForm.itemType === 'PRODUCT'">
                <el-form-item label="Quantity" prop="quantity">
                <el-input-number
                  v-model="cdNoteForm.quantity"
                  :controls="false" :precision="3" :min="0"
                  @blur="calculateSummaryInfo"
                  :disabled="isCdFormAllFieldsDisabled"
                >
                </el-input-number>
                </el-form-item>
               <el-form-item label="Unit" prop="unit" >
                <el-select v-model="cdNoteForm.unit" :disabled="(propsView === 'CREATE_CD_NOTE' && !cdNoteForm.invoiceNo) || isCdFormAllFieldsDisabled">
                  <el-option
                    v-for="item in UNIT_OPTIONS"
                    :key="item.value" :label="item.label" :value="item.value"
                  />
                </el-select>
                </el-form-item>
              </div>
              <el-form-item label="Quantity" prop="quantity" v-else>
                <el-input-number
                  v-model="cdNoteForm.quantity"
                  :controls="false" :precision="3" :min="0"
                  @blur="calculateSummaryInfo"
                  :disabled="isCdFormAllFieldsDisabled"
                >
                </el-input-number>
              </el-form-item>
              <el-form-item label="Price" prop="price" >
                <el-input-number
                  v-model="cdNoteForm.price"
                  :controls="false" :precision="3" :min="0"
                  @blur="calculateSummaryInfo"
                  :disabled="isCdFormAllFieldsDisabled"
                />
              </el-form-item>
              <el-form-item label="Amount" prop="amount">
                <el-input-number
                  v-model="cdNoteForm.amount"
                  :controls="false" :precision="2" :min="0"
                  @blur="calculateSummaryInfo"
                  :disabled="true"
                />
              </el-form-item>
              <el-form-item label="Supply Date"  :required="true" prop="supplyDate">
                <el-date-picker
                  v-model="cdNoteForm.supplyDate"
                  :disabled="isCdFormAllFieldsDisabled"
                />
              </el-form-item>
              <el-form-item label="Receipt No" prop="receiptNo" v-if="cdNoteForm.itemType === 'PRODUCT'">
                <el-input :disabled="true" v-model="cdNoteForm.receiptNo"/>
              </el-form-item>
            </div>
          </div>
        </div>
        <div  class="basic-card-block">
          <h1>Payment Information</h1>
          <div>
            <div class="div-frac-14">
              <el-form-item label="Exchange Currency" prop="exchangeCurrency">
                <el-select v-model="cdNoteForm.exchangeCurrency" :disabled="isCdFormAllFieldsDisabled">
                  <el-option
                    v-for="item in currencyList"
                    :key="item.id" :label="item.code" :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Exchange Currency Rate" prop="exchangeCurrencyRate">
                <el-input-number
                  v-model="cdNoteForm.exchangeCurrencyRate"
                  :controls="false" :precision="3" :min="0"
                  @blur="calculateSummaryInfo"
                  :disabled="isCdFormAllFieldsDisabled"
                >
                </el-input-number>
              </el-form-item>
              <el-form-item label="GST Type" prop="gstType">
                <el-select v-model="cdNoteForm.gstType" @change="handleGstTypeSelect" :disabled="isCdFormAllFieldsDisabled">
                  <el-option
                    v-for="item in GST_TYPE_OPTIONS"
                    :key="item.value" :label="item.label" :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="GST %"
                prop="gstPercentage">
                <el-input-number
                  v-model="cdNoteForm.gstPercentage"
                  :controls="false" :precision="2" :min="0"
                  @blur="calculateSummaryInfo"
                  disabled
                />
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
      <div class="div-form-main-right-summary">
        <div>
          <h4 v-if="propsView === 'EDIT_CD_NOTE' ">{{cdNoteForm.noteType === 'CREDIT_NOTE' ? 'Credit' : 'Debit'}} Note Number</h4>
          <div class="div-cd-note-payment-summary-note-no" v-if="propsView === 'EDIT_CD_NOTE'">
            <h1>{{noteNo}}</h1>
            <i class="el-icon-document" @click="previewFile(cdNotePdfFileId)"/>
          </div>
          <div class="div-cd-note-payment-summary-top" v-if="propsView === 'EDIT_CD_NOTE' || propsView === 'MAIN_VIEW'">
            <div>
              <p>{{cdNoteForm.noteType === 'CREDIT_NOTE' ? 'Credit' : 'Debit'}} Note Status</p>
              <div class="status-tag" :style="getStatusTagStyle(cdNoteBasInfo.status)">
                {{capitalizeFirstLetter(cdNoteForm.status, '_')}}
              </div>
            </div>
            <div>
              <p>Created On</p>
              <p class="p-cd-note-summary-date">{{$moment(cdNoteBasInfo.createdAt).format('DD-MM-YYYY HH:mm') }}</p>
            </div>
            <div v-if="cdNoteBasInfo.dateApproved">
              <p>Date Approved</p>
              <p class="p-cd-note-summary-date">{{$moment(cdNoteBasInfo.dateApproved).format('DD-MM-YYYY HH:mm')}}</p>
            </div>
          </div>
          <h4>{{cdNoteForm.noteType === 'CREDIT_NOTE' ? 'Credit' : 'Debit'}} Note Summary</h4>
          <div class="div-cd-note-payment-currency">
            <p>Payment Currency: {{cdNoteForm.paymentCurrency || 'N.A.'}}</p>
          </div>
          <div class="div-cd-note-payment-summary">
            <div>
              <p>Subtotal</p>
              <div><hr/></div>
              <p class="p-cd-note-summary-amt" v-if="cdNotePaymentInfo.subTotal">{{`${cdNoteForm.paymentCurrency} ${numberWithCommas(cdNotePaymentInfo.subTotal, 2)}`}}</p>
              <p class="p-cd-note-summary-amt" v-else>{{cdNoteForm.paymentCurrency}}  0.00</p>
            </div>
          </div>
          <hr style="border-top: 1px solid #F4F4F4;"/>
          <div class="div-cd-note-payment-currency">
            <p>Exchange Currency: {{cdNoteForm.exchangeCurrency || 'N.A.'}} (for GST purpose)</p>
          </div>
          <div class="div-cd-note-payment-summary">
            <div>
              <p>Total Amount before GST</p>
              <div><hr/></div>
              <p class="p-cd-note-summary-amt" v-if="cdNotePaymentInfo.exchangeAmount">{{`${cdNoteForm.exchangeCurrency} ${numberWithCommas(cdNotePaymentInfo.exchangeAmount, 2)}`}}</p>
              <p class="p-cd-note-summary-amt" v-else>{{cdNoteForm.exchangeCurrency}} 0.00</p>
            </div>
            <div>
              <p>GST Amount</p>
              <div><hr/></div>
              <p class="p-cd-note-summary-amt" v-if="cdNotePaymentInfo.exchangeTax">{{`${cdNoteForm.exchangeCurrency} ${numberWithCommas(cdNotePaymentInfo.exchangeTax, 2)}`}}</p>
              <p class="p-cd-note-summary-amt" v-else>{{cdNoteForm.exchangeCurrency}} 0.00</p>
            </div>
            <div>
              <p>Total Amount after GST</p>
              <div><hr/></div>
              <p class="p-cd-note-summary-amt" v-if="cdNotePaymentInfo.exchangeNoteTotal">{{`${cdNoteForm.exchangeCurrency} ${numberWithCommas(cdNotePaymentInfo.exchangeNoteTotal, 2)}`}}</p>
              <p class="p-cd-note-summary-amt" v-else>{{cdNoteForm.exchangeCurrency}} 0.00</p>
            </div>
          </div>
          <div class="div-cd-note-payment-summary-note-total">
            <div>
              <h4>Note Total</h4>
              <h1 v-if="cdNotePaymentInfo.noteTotal">{{`${cdNoteForm.paymentCurrency} ${numberWithCommas(cdNotePaymentInfo.noteTotal, 2)}`}}</h1>
              <h1 v-else>{{cdNoteForm.paymentCurrency}}  0.00</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dialog-select-invoice :isSellerView="isSellerView" :dialogSelectInvoice="dialogSelectInvoice" :propsInvoiceSelected="cdNoteInvoiceSelected"
      @handleConfirmInvoiceClick='handleConfirmInvoiceClick' @handleInvoiceDialogClose="dialogSelectInvoice = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { CDNOTE_STATUS, rule, UNIT_OPTIONS, GST_TYPE_OPTIONS, ORDER_ITEM_TYPE_OPTIONS, PAYMENT_TERM_OPTIONS } from '@/constants.js'
import { getStatusTagStyle, numberWithCommas, capitalizeFirstLetter, getTodayISOString, openBlobUrlInNewTab } from '@/utils/index.js'
import DialogSelectInvoice from './DialogSelectInvoice'
import { getInvoiceById } from '@/services/modules/invoice'
import { getCdNoteById, verifyCdNoteCA, createCdNote, updateCdNote } from '@/services/modules/cdNote'
import { getFileBlobUrl } from '@/services/modules/comm.js'

export default {
  name: 'CdNoteForm',
  components: {
    DialogSelectInvoice
  },
  props: {
    cdNoteId: String,
    data: Object,
    isSellerView: Boolean,
    passedNoteType: String,
    propsView: String,
    isViewOnly: Boolean
  },
  data () {
    const rules = {
      invoiceNo: rule,
      term: rule,
      noteDueDate: rule,
      noteDate: rule,
      reason: rule,
      itemType: rule,
      description: rule,
      unit: rule,
      supplyDate: [{ required: true, validator: this.supplyDateValidation, message: 'This field is required', trigger: ['blur', 'change'] }],
      amount: [{ required: true, validator: this.qtyPriceValidation, trigger: ['blur', 'change'] }],
      price: [{ required: true, validator: this.qtyPriceValidation, trigger: ['blur', 'change'] }],
      quantity: [{ required: true, validator: this.qtyPriceValidation, trigger: ['blur', 'change'] }],
      exchangeCurrency: rule,
      exchangeCurrencyRate: [{ required: true, validator: this.exchangeCurrencyRateValidation, trigger: ['blur', 'change'] }],
      gstType: rule,
      gstPercentage: rule,
      termDate: [{ required: true, validator: this.supplyDateValidation, message: 'This field is required', trigger: ['blur', 'change'] }],
      creditDay: [{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]
    }

    const initCdNoteForm = {
      noteType: null,
      createdByInvoiceId: null,
      invoiceNo: null,
      customerCompanyName: null,
      orderInfo: null,
      noteDueDate: null,
      noteDate: getTodayISOString(),
      reason: null,
      itemType: null,
      description: null,
      invoiceItemId: null,
      quantity: null,
      unit: null,
      supplyDate: null,
      price: null,
      amount: null,
      receiptNo: null,
      exchangeCurrency: 'SGD',
      exchangeCurrencyRate: null,
      gstType: null,
      gstPercentage: null,
      paymentCurrency: null,
      term: null, // 'DEBIT_NOTE'
      termDate: getTodayISOString(), // 'DEBIT_NOTE' && term ==='SPECIFIC_DATE'
      creditDay: 0 // 'DEBIT_NOTE' && term !=='SPECIFIC_DATE'
    }
    const initCdNotePaymentInfo = {
      exchangeAmount: null, // Total Amount before GST
      exchangeTax: null, // GST Amount
      exchangeNoteTotal: null, // Total Amount after GST
      subTotal: null, // Subtotal
      taxTotal: null, // GST
      noteTotal: null // Note Total
    }
    const initCdNoteBasInfo = {
      createdAt: null,
      dateApproved: null,
      status: null
    }
    const cdPagination = {
      pageSize: 50,
      pageNumber: 1,
      total: 0
    }
    const invoicePagination = {
      pageSize: 50,
      pageNumber: 1,
      total: 0
    }
    return {
      rule,
      ORDER_ITEM_TYPE_OPTIONS: Object.freeze(ORDER_ITEM_TYPE_OPTIONS),
      PAYMENT_TERM_OPTIONS: Object.freeze(PAYMENT_TERM_OPTIONS),
      CDNOTE_STATUS,
      GST_TYPE_OPTIONS,
      UNIT_OPTIONS,
      formLoading: false,
      rules,
      cdPagination,
      dialogSelectInvoice: false,
      invoiceTableData: [],
      invoicePagination,
      radioData: null,
      isCdFormAllFieldsDisabled: false,
      cdNotePdfFileId: null,
      cdNoteInvoiceSelected: null,
      cdNoteForm: initCdNoteForm,
      cdNotePaymentInfo: initCdNotePaymentInfo,
      cdNoteBasInfo: initCdNoteBasInfo,
      noteNo: null,
      invoiceItems: null
    }
  },
  created () {
    this.getInitData()
    if (!this.currencyList) {
      this.$store.dispatch('getCurrencyList')
    }
  },
  computed: {
    ...mapState(['currencyList']),
    itemTypeList () {
      let options = this.ORDER_ITEM_TYPE_OPTIONS.filter(val => val.value !== 'COMMISSION')
      const getItemTypeList = (items) => {
        options.forEach((type, index) => {
          const i = items.findIndex(item => item.itemType === type.value)
          if (type.value === 'PRODUCT' && i === -1) {
            options = options.filter(val => val.value !== type.value)
          }
        })
      }
      if (this.invoiceItems) {
        if (!this.isSellerView) {
          const idx = this.invoiceItems.findIndex(item => item.itemType === 'COMMISSION')
          if (idx !== -1) {
            options = this.ORDER_ITEM_TYPE_OPTIONS.filter(val => val.value === 'COMMISSION')
          } else {
            getItemTypeList(this.invoiceItems)
          }
        } else {
          getItemTypeList(this.invoiceItems)
        }
      }
      return options
    },
    dropDownDescList () {
      let value
      if (this.invoiceItems && this.invoiceItems.length > 0) {
        value = this.invoiceItems.filter(item => item.itemType === this.cdNoteForm.itemType)
      }
      return value
    }
  },
  methods: {
    getStatusTagStyle,
    numberWithCommas,
    capitalizeFirstLetter,
    getFileBlobUrl,
    openBlobUrlInNewTab,
    previewFile (fileId) {
      this.getFileBlobUrl(fileId).then(url => {
        this.openBlobUrlInNewTab(url)
      }).catch(e => {
        
      })
    },
    handleConfirmInvoiceClick (selection) {
      this.cdNoteInvoiceSelected = selection
      if (this.cdNoteInvoiceSelected) {
        getInvoiceById(this.cdNoteInvoiceSelected.id).then(res => {
          if (res?.code === 1000) {
            const invoice = res.data
            this.cdNoteForm.createdByInvoiceId = invoice.id
            this.invoiceItems = invoice.invoiceItems
            this.cdNoteForm.invoiceNo = invoice?.userEnteredInvoiceNo ?? invoice?.systemGeneratedInvoiceNo
            this.cdNoteForm.customerCompanyName = this.isSellerView ? invoice.buyerCompanyName : invoice.sellerCompanyName
            this.cdNoteForm.orderInfo = invoice.orderVesselName ? invoice.orderVesselName : invoice.orderTerminal
            this.cdNoteForm.exchangeCurrency = invoice.exchangeCurrency
            this.cdNoteForm.exchangeCurrencyRate = invoice.exchangeCurrencyRate
            this.cdNoteForm.paymentCurrency = invoice.paymentCurrency
            this.dialogSelectInvoice = false
          }
        })
      }
    },
    getInitData () {
      this.formLoading = true
      if (this.cdNoteId && this.propsView === 'EDIT_CD_NOTE') {
        getCdNoteById(this.cdNoteId).then(res => {
          if (res?.code === 1000) {
            const cdNote = res.data
            this.noteNo = cdNote?.systemGeneratedNoteNo
            this.cdNotePdfFileId = cdNote?.noteFileId
            this.cdNoteForm = cdNote
            this.cdNoteForm.description = cdNote?.description ?? cdNote?.itemName
            this.cdNoteForm.invoiceNo = cdNote.createdByInvoice.userEnteredInvoiceNo
            this.cdNoteForm.orderInfo = cdNote.createdByInvoice.orderInfo
            this.cdNoteForm.createdByInvoiceId = cdNote.createdByInvoice.id
            this.cdNoteForm.customerCompanyName = this.isSellerView ? cdNote.createdByInvoice.buyerCompanyName : cdNote.createdByInvoice.sellerCompanyName
            const paymentInfoKeys = Object.keys(this.cdNotePaymentInfo)
            this.cdNotePaymentInfo = { ...this.$_.pick(cdNote, paymentInfoKeys) }
            const basInfofoKeys = Object.keys(this.cdNoteBasInfo)
            this.cdNoteBasInfo = { ...this.$_.pick(cdNote, basInfofoKeys) }
            getInvoiceById(cdNote.createdByInvoice.id).then(res => {
              this.invoiceItems = res.data.invoiceItems
              this.cdNoteInvoiceSelected = res.data
            })
            this.formLoading = false
          }
        })
        this.isCdFormAllFieldsDisabled = true
      } else {
        this.cdNoteForm.noteType = this.$props.passedNoteType
        this.formLoading = false
      }
    },
    resetCdNoteForm () {
      this.cdNoteForm = this.initCdNoteForm
      this.cdNotePaymentInfo = this.initCdNotePaymentInfo
      this.cdNoteInvoiceSelected = null
      this.radioData = null
      this.isCdFormAllFieldsDisabled = false
    },
    qtyPriceValidation (rule, value, callback) {
      if ((!value || value === 0)) {
        callback(new Error('This field is required, must be greater than 0'))
      } else {
        callback()
      }
    },
    exchangeCurrencyRateValidation (rule, value, callback) {
      if (!value || value === 0) {
        callback(new Error('This field is required'))
      } else {
        callback()
      }
    },
    supplyDateValidation (rule, value, callback) {
      if (!value) {
        callback(new Error('This field is required'))
      } else {
        callback()
      }
    },
    calculateSummaryInfo () {
      // if (this.cdNoteForm.itemType === 'PRODUCT') {
      this.cdNoteForm.amount = this.cdNoteForm.quantity * this.cdNoteForm.price
      // }
      this.cdNotePaymentInfo.subTotal = this.cdNoteForm.amount
      this.cdNotePaymentInfo.taxTotal = this.cdNotePaymentInfo.subTotal * (this.cdNoteForm.gstPercentage / 100)
      this.cdNotePaymentInfo.noteTotal = this.cdNotePaymentInfo.subTotal + this.cdNotePaymentInfo.taxTotal
      this.cdNotePaymentInfo.exchangeAmount = this.cdNotePaymentInfo.subTotal * this.cdNoteForm.exchangeCurrencyRate
      this.cdNotePaymentInfo.exchangeTax = this.cdNotePaymentInfo.taxTotal * this.cdNoteForm.exchangeCurrencyRate
      this.cdNotePaymentInfo.exchangeNoteTotal = this.cdNotePaymentInfo.noteTotal * this.cdNoteForm.exchangeCurrencyRate
    },
    handleGstTypeSelect (gstType) {
      this.cdNoteForm.gstPercentage = gstType === 'S' ? 7 : 0
      this.calculateSummaryInfo()
    },
    handleInvoiceSelected (selection) {
      this.cdNoteInvoiceSelected = selection
      this.radioData = selection
      this.getTypeItemList(selection)
    },
    handleInvoicePageChange (pageNumber) {
      this.getInvoice(this.invoicePagination.pageSize, pageNumber - 1)
    },
    handleInvoicePageSizeChange (pageSize) {
      this.getInvoices(pageSize, 0, true)
    },
    handleCdNoteItemSelect (invoiceItem) {
      if (typeof invoiceItem === 'object') {
        this.cdNoteForm.description = invoiceItem.description
        this.cdNoteForm.invoiceItemId = invoiceItem.id
        this.cdNoteForm.supplyDate = invoiceItem.supplyDate
        this.cdNoteForm.quantity = invoiceItem.quantity
        this.cdNoteForm.price = invoiceItem.price
        this.cdNoteForm.unit = invoiceItem.unit
        this.cdNoteForm.amount = invoiceItem.amount
        this.cdNoteForm.receiptNo = invoiceItem.receiptNo
        this.cdNoteForm.gstType = invoiceItem.gstType
        this.cdNoteForm.gstPercentage = invoiceItem.gstPercentage
      } else {
        this.cdNoteForm.itemName = invoiceItem
      }
      this.calculateSummaryInfo()
    },
    handleCdNoteItemTypeSelect (selection) {
      this.invoiceItems = this.cdNoteInvoiceSelected.invoiceItems
      this.cdNoteForm.description = null
    },
    handleCloseCdNoteForm (action) {
      this.resetCdNoteForm()
      this.$emit('handleCloseCdNoteForm', action)
    },
    getCdNoteFormPayloadData () {
      if (this.propsView === 'EDIT_CD_NOTE') {
        this.cdNoteForm.id = this.cdNoteId
        if (!this.cdNoteForm.itemName && this.dropDownDescList?.length > 0) {
          this.cdNoteForm.itemName = this.dropDownDescList.filter(l => l.description === this.cdNoteForm.description)[0]
        }
      }
      if (this.cdNoteForm.invoiceItemId) {
        this.cdNoteForm.itemName = this.cdNoteForm.description
      }
      const data = { ...this.cdNoteForm }
      if (this.cdNoteForm.noteType === 'DEBIT_NOTE') {
        if (data.term !== 'SPECIFIC_DATE') {
          delete data.termDate
        }
      } else {
        delete data.term
        delete data.termDate
        delete data.creditDay
      }
      return data
    },
    handleVerifyCdNoteCA (cdNote) {
      return new Promise((resolve, reject) => {
        verifyCdNoteCA(cdNote).then(res => {
          if (res?.code === 1000) {
            if (res.data) {
              const title = 'Confirm Submission?'
              const body = 'Credit Authorisation is required. Please enter a reason.'
              const validateCAField = (input) => {
                if (input.length < 1) {
                  return 'This field is required.'
                } else {
                  if (input.length > 500) {
                    return 'Character limit of 500 is exceeded.'
                  }
                  return true
                }
              }
              this.$prompt(body, title, {
                confirmButtonText: this.$t('submit'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning',
                inputType: 'textarea',
                inputValidator: validateCAField
              }).then(({ value }) => {
                resolve(value)
              }).catch(() => {})
            } else {
              resolve(false)
            }
          }
        }).catch(e => {
          this.formLoading = false
          reject(e)
        })
      })
    },
    handleCreateCdNote (cdNote) {
      this.formLoading = true
      createCdNote(cdNote).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Credit/ Debit Note has been created.')
          this.$emit('handleCloseCdNoteForm', 'CREATE')
        }
        this.formLoading = false
      }).catch(e => {})
    },
    handleUpdateCdNote (cdNote) {
      this.formLoading = true
      updateCdNote(cdNote).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Credit/ Debit Note has been updated.')
          this.$emit('handleCloseCdNoteForm', 'UPDATE')
        }
        this.formLoading = false
      })
    },
    submitCdNote (isCreateCdNote) {
      this.$refs.cdNoteForm.validate((valid) => {
        if (valid) {
          const cdNote = this.getCdNoteFormPayloadData()
          this.handleVerifyCdNoteCA(cdNote).then(val => {
            if (val) {
              cdNote.creditAuthorizationRequestRemark = val
            }
            isCreateCdNote ? this.handleCreateCdNote(cdNote) : this.handleUpdateCdNote(cdNote)
          })
        }
      })
    },
    handleChangeTerm (val) {
      if (val === 'IMMEDIATE') { this.cdNoteForm.creditDay = 0 }
    }
  }
}
</script>
<style scoped lang="scss">
  @import "../index.scss";
</style>
